@import "../styles.scss";

#carousel-item {
    z-index: 3;
    /*height: 900px;*/
}

.home-carousel {
    z-index: 3 !important;
}

.home-quote {
    z-index: 1 !important;
    margin: 0 auto !important;
}

.home-about-me {
    z-index: 2 !important;
    margin: 0 auto;
    padding: 5% 15%;
    background-color: $lightgrey;
}

.carousel-caption-p {
    text-shadow: 0 0 10px black;
}

.carousel-caption-h3 {
    text-shadow: 0 0 10px black;
}

.page-quote {
    background-color: $darkgrey !important;
    //background-color: transparent !important;
    height: 100%;
    margin: 0 auto !important;
    padding: 100px 20px !important;
}

.quote-container {
    display: table;
    height: 100%;
    margin: 0 15% !important;
}

.quote {
    font-family: $defaultFont;
    font-size: 40px;
    color: $lightgrey;
    display: table-cell;
    margin: 0 auto !important;
    text-align: center;
    vertical-align: middle;
}

.home-about-me-subtitle {
    text-align: center;
    margin: 10px 10px 50px 10px;
}
