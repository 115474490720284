@import "styles";

ul.no-bullets {
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */
}

hr.solid {
    border-top: 3px solid $lightgrey;
}

$iconSize: 50px;

i.social {
    width: $iconSize;
    height: $iconSize;
    display: inline-block;
    background-size: $iconSize $iconSize !important;
}