//@import "public/assets/fonts";

// Variables

// Colours
// Greys
$darkgrey: rgb(52, 58, 64);
$grey: grey;
$lightgrey: lightgrey;

// Primary
$darkblue: #00004b;

// Pastels
$pastelred: #e5707e;
$pastelorange: #e08f62;
$pastelyellow: #faf0af;
$pastelgreen: #c6ebc9;
$pastelblue: #a7c5eb;
$pastelpurple: #949cdf;

:export {
  darkgrey: $darkgrey;
  grey: $grey;
  lightgrey: $lightgrey;
  
  darkblue: $darkblue;

  pastelred: $pastelred;
  pastelorange: $pastelorange;
  pastelyellow: $pastelyellow;
  pastelgreen: $pastelgreen;
  pastelblue: $pastelblue;
  pastelpurple: $pastelpurple;
}

// Fonts
@font-face {
  font-family: "Roboto", "Open Sans", sans-serif;
  src: url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
}
$defaultFont: "Roboto", "Open Sans", sans-serif;

body {
  height: 100%;
  background-color: $darkgrey;
  margin: 0 auto;
  font-family: $defaultFont;
  color: $lightgrey;
}

.h1 {
  color: $lightgrey;
}

.p {
  color: $lightgrey;
}
